<template lang="pug">
	div.page-reports
		reports-widget(:config="reportsConfig", :offer_id="filters.offer_id", :placement_id="filters.placement_id")
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
	name: 'Reports',
	async beforeRouteEnter(to, from, next){
		next(async(vm)=>{
			vm.filters.offer_id = to.query.offer_id || '';
			vm.filters.placement_id = to.query.placement_id || '';
			return vm;
		});
	},
	data(){
		return {
			filters: {
				offer_id: '',
				placement_id: '',
			},
			reportsConfig: {
				loadOnStart: true
			}
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Reports'}
		]);
	},
}
</script>
